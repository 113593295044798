import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-cde53b4c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "nav"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "serch"
};
const _hoisted_6 = {
  class: "inputBox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  const _component_el_menu_item = _resolveComponent("el-menu-item");

  const _component_el_sub_menu = _resolveComponent("el-sub-menu");

  const _component_el_menu = _resolveComponent("el-menu");

  const _component_el_input = _resolveComponent("el-input");

  const _component_search = _resolveComponent("search");

  const _component_el_icon = _resolveComponent("el-icon");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "logo",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    (...args) => _ctx.toIndex && _ctx.toIndex(...args))
  }, [_createVNode(_component_el_image, {
    src: _ctx.imgUrl(_ctx.configData.logoFileUrl),
    lazy: "",
    fit: "cover"
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_menu, {
    "default-active": _ctx.activeIndex,
    class: "nav-el-mene",
    mode: "horizontal",
    ellipsis: false,
    onSelect: _ctx.handleSelect
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productBarList, (item, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: item.code
      }, [item.children && item.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 0,
        index: item.code,
        "popper-class": "dropmenu",
        onClick: $event => _ctx.changeMenu(item, item)
      }, {
        title: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(item.name), 1)]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (ico, i) => {
          return _openBlock(), _createBlock(_component_el_menu_item, {
            index: ico.code
          }, {
            default: _withCtx(() => [_createElementVNode("span", {
              onClick: $event => _ctx.changeMenu(item, ico)
            }, _toDisplayString(ico.name), 9, _hoisted_3)]),
            _: 2
          }, 1032, ["index"]);
        }), 256))]),
        _: 2
      }, 1032, ["index", "onClick"])) : _createCommentVNode("", true), !item.children || item.children.length == 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 1,
        index: item.code
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          onClick: $event => _ctx.changeMenu(item, item)
        }, _toDisplayString(item.name), 9, _hoisted_4)]),
        _: 2
      }, 1032, ["index"])) : _createCommentVNode("", true)], 64);
    }), 128))]),
    _: 1
  }, 8, ["default-active", "onSelect"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_input, {
    modelValue: _ctx.queryText,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.queryText = $event),
    placeholder: "请输入搜索内容",
    onKeypress: _withKeys(_ctx.getData, ["enter"])
  }, null, 8, ["modelValue", "onKeypress"]), _createVNode(_component_el_icon, {
    class: "search-icon",
    onClick: _ctx.getData
  }, {
    default: _withCtx(() => [_createVNode(_component_search)]),
    _: 1
  }, 8, ["onClick"])])])]);
}