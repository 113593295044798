import { defineComponent, nextTick, onMounted, provide, ref, reactive, toRefs } from "vue";
import { setRem } from "@/utils/setRem";
import { getOrgCodeByConfig } from "@/http/home";
export default defineComponent({
  setup() {
    const isActive = ref(true);

    const _this = reactive({
      openFlag: false
    });

    const reload = () => {
      isActive.value = false; // 页面元素被重新渲染之后

      nextTick(() => {
        isActive.value = true;
      });
    };

    provide("reload", reload);
    const methods = {
      async getOrgCode() {
        const res = await getOrgCodeByConfig({
          siteAddress: window.location.origin
        });

        if (res.data.code == 200 && res.data.data) {
          sessionStorage.setItem("orgCode", res.data.data);
          _this.openFlag = true;
        }
      }

    };
    onMounted(() => {
      setRem(); // // 改变窗口大小时重新设置 rem, 搭配防抖函数使用

      window.addEventListener("resize", setRem, false);
      methods.getOrgCode();
    });
    return {
      isActive,
      ...methods,
      ...toRefs(_this)
    };
  }

});