/*
 * @Description:
 * @Autor: shanbian
 * @Date: 2023-03-16 16:21:12
 * @LastEditors: youjiahui youjiahui@dedibot.com
 * @LastEditTime: 2023-08-17 13:59:56
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../layout/index.vue";
import Product from "../layout/product/index.vue";
import Solution from "../layout/solution/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/index",
    name: "Index",
    component: Home,
    children: [
      {
        path: "test",
        component: () => import("@/views/test/index.vue"),
      },
      {
        path: "index",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/about/index.vue"),
        meta: {
          keepAlive: true, // 组件需要缓存
        },
      },
      {
        path: "hr",
        component: () => import("@/views/recruit/index.vue"),
      },
      {
        path: "column",
        component: () => import("@/views/column/index.vue"),
      },
      {
        path: "columnDisplay",
        component: () => import("@/views/columnDisplay/index.vue"),
      },
      {
        path: "service",
        component: () => import("@/views/service/index.vue"),
      },
      {
        path: "product",
        component: Product,
        children: [
          {
            path: "detail/:id",
            component: () => import("@/views/product/index.vue"),
          },
          {
            path: "list/:id",
            component: () => import("@/views/product/index.vue"),
          },
        ],
      },
      {
        path: "productCenter",
        component: () => import("@/views/product/productCenter.vue"),
      },
      {
        path: "solution",
        component: Solution,
        children: [
          {
            path: "detail/:id",
            component: () => import("@/views/solution/index.vue"),
          },
          {
            path: "list/:id",
            component: () => import("@/views/solution/index.vue"),
          },
        ],
      },
      {
        path: "/newsDetail",
        component: () => import("@/views/news/index.vue"),
      },
      {
        path: "/deviceDetailsByCode",
        name: "/deviceDetailsByCode",
        component: () => import("@/views/service/deviceDetailsByCode.vue"),
      },
      {
        path: "contactUs",
        name: "/contactUs",
        component: () => import("@/views/service/components/contactUs.vue"),
      },
      {
        path: "serch",
        name: "/serch",
        component: () => import("@/views/serch/index.vue"),
      },
    ],
  },
];

let router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior() {
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return { left: 0, top: 0 };
  },
});
// router.afterEach(()=>{
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
//   window.scrollTo(0, 0);
// })
// router.beforeEach((to, from, next) => {
//     document.body.scrollTop = 0;
//     document.documentElement.scrollTop = 0;
//     // 兼容IE
//     window.scrollTo(0, 0);
//     next();
//   })
export default router;
