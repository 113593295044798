import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/theme-chalk/el-message.css";
import ZhLocale from "element-plus/lib/locale/lang/zh-cn"; // 中文
import EhLocale from "element-plus/lib/locale/lang/en"; // 英文
import I18n from "@/locales/index"; //引入i18n组件

let locale = "";
//控制 element-plus 组件语言
if (I18n.global.fallbackLocale == "zh") {
  locale = ZhLocale as unknown as string;
} else {
  locale = EhLocale as unknown as string;
}
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// 去警告
app.config.warnHandler = () => null;
// 自定义svg图标引用
import installIcons from "./icons";
installIcons(app);
app.use(store);
app.use(router);
app.use(I18n);
app.mount("#app");
