const homeCN = {
  productCenter: {
    title: '产品中心'
  },
  solution: {
    title: 'SOLUTION 解决方案'
  },
  pressCenter: {
    title: 'NEWS 新闻中心'
  },
  partners: {
    title: 'PARTNER 合作伙伴'
  },
  contactUs: {
    title: '获取更多信息请与我们联系',
    btnTxt: '联系我们'
  }
}
module.exports = homeCN;