import $http from "./index";

export const getResolutionNav = () =>
  $http({
    url: `/solution/solutionCategory/data/sideMenu`,
    method: "post",
  });

// 产品中心详情
export const getSolutionDeail = (data: any) =>
  $http({
    url: `/solution/solutionDetail/data/detail`,
    method: "post",
    data
  });
