const homeEN = {
	productCenter:{
    title:'Product Center'
  },
  solution:{
    title:'Solution'
  },
  pressCenter:{
    title:'Press Center'
  },
  partners:{
    title:'Partners'
  },
  contactUs:{
    title:'Please contact us for more information',
    btnTxt:'Contact Us'
  }
}
module.exports = homeEN;