import { defineComponent, ref, onMounted, reactive, toRefs, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import emitter from "@/mitt";
import { Search } from "@element-plus/icons-vue";
import { imgUrl } from "@/utils/urlManage";
export default defineComponent({
  props: {
    configData: {
      default: {}
    },
    productBarList: {
      default: []
    }
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const _this = reactive({
      queryText: ""
    });

    const activeIndex = ref("");

    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath);
    };

    const methods = {
      // 前往首页
      toIndex() {
        router.push("/index");
      },

      //搜索页搜索
      getData() {
        router.push({
          path: `/serch`,
          query: {
            text: _this.queryText
          }
        });
        emitter.emit("pro-serch");
      },

      async changeMenu(item, ico) {
        activeIndex.value = ico.code;

        if (ico.typeCode == "inbuilt") {
          if (ico.code == "serviceSupport") {
            await router.push(`${methods.typeLink(ico.link)}?onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            emitter.emit("pro-getServiceMenu");
          } else {
            await router.push(`${methods.typeLink(ico.link)}?onlyName=${ico.name}&subtitle=${ico.subtitle}&code=${ico === null || ico === void 0 ? void 0 : ico.code}`);
            emitter.emit("pro-getServiceMenu");
          }
        } else if (ico.typeCode == "link") {
          window.open(ico.link, "_blank");
        } else if (ico.typeCode == "defined") {
          router.push(`/columnDisplay?partCode=${ico.code}&code=${""}`);
        } else {
          router.push(`/columnDisplay?partCode=${item.code}&code=${ico.code}`);
        }
      },

      typeLink(str) {
        switch (str) {
          case "index":
            return "/index";

          case "about":
            return `/about`;

          case "join":
            return "/hr";

          case "contact":
            return "/contactUs";

          case "service":
            return "/service";
        }
      },

      typemeau() {
        activeIndex.value = "";
        props.productBarList.forEach(ele => {
          if (ele.typeCode == "inbuilt") {
            if (route.path == methods.typeLink(ele.link)) {
              activeIndex.value = ele.code;
            } else if (route.path == "/deviceDetailsByCode" && ele.link == "service") {
              activeIndex.value = ele.code;
            } else if (route.path == "/newsDetail" && ele.link == "about") {
              activeIndex.value = ele.code;
            }
          }

          if (ele.children) {
            ele.children.forEach(element => {
              if (element.typeCode == "inbuilt") {
                if (route.path == methods.typeLink(element.link)) {
                  activeIndex.value = element.code;
                }
              }
            });
          }
        });

        if (route.query.partCode) {
          activeIndex.value = route.query.partCode;
        }
      }

    };
    onMounted(() => {
      if (route.query.partCode) {
        activeIndex.value = route.query.partCode;
      }
    });
    watch(() => props.productBarList, (newValue, oldValue) => {
      let flag = true;
      methods.typemeau();
    });
    watch(() => route.path, (newValue, oldValue) => {
      let flag = true;
      methods.typemeau();

      if (route.query.partCode) {
        activeIndex.value = route.query.partCode;
      }
    });
    return { ...methods,
      ...toRefs(_this),
      Search,
      imgUrl,
      activeIndex,
      handleSelect
    };
  }

});