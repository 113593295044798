import $http from "./index";

export const getProductNav = () =>
  $http({
    url: `/productDisplay/productCategory/data/sideMenu`,
    method: "post",
  });
// 获取产品中心
export const getProductCenter = () =>
  $http({
    url: `/productDisplay/productCategory/data/show`,
    method: "get",
  });

// 产品中心详情--products下的详情数据
export const getProductsDeail = (data: any) =>
  $http({
    url: `/productDisplay/productDetail/data/detail`,
    method: "post",
    data
  });

// 产品中心详情--子类别
export const getChildDeail = (data: any) =>
  $http({
    url: `/productDisplay/productCategory/edit/first0`,
    method: "post",
    data
  });