import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import emitter from "@/mitt";
import { imgUrl } from "@/utils/urlManage";
export default defineComponent({
  props: {
    configData: {
      default: {}
    },
    productBarList: {
      default: []
    }
  },

  setup() {
    const router = useRouter();
    const methods = {
      //跳转产品中心
      async goProduct(id) {
        await router.push({
          path: `/product/list/${id}`
        });
        emitter.emit("pro-getMenu");
      },

      //跳转解决方案
      async goSolution(id) {
        await router.push({
          path: `/solution/list/${id}`
        });
        emitter.emit("pro-getSolutionMenu");
      },

      //跳转服务支持
      async goService(id) {
        await router.push({
          path: `/service`,
          query: {
            code: id
          }
        });
        emitter.emit("pro-getServiceMenu");
      },

      changeMenu(item, ico) {
        if (ico.typeCode == "inbuilt") {
          if (item.code == ico.code) {
            router.push(`${methods.typeLink(ico.link)}?onlyName=${ico.name}&subtitle=${ico.subtitle}`);
          } else {
            if (item.typeCode == "inbuilt") {
              if (ico.link == "service") {
                router.push(`${methods.typeLink(ico.link)}?code=${ico.code}`);
              } else {
                router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&url=${methods.typeLink(item.link)}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
              }
            } else if (item.typeCode == "link") {
              router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&typeCode=${item.typeCode}&url=${item.link}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            } else if (ico.typeCode == "defined") {
              router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&url=/columnDisplay?partCode=${ico.code}&code=${""}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            }
          }
        } else if (ico.typeCode == "link") {
          window.open(ico.link, "_blank");
        } else if (ico.typeCode == "defined") {
          router.push(`/columnDisplay?partCode=${ico.code}&code=${""}`);
        } else {
          router.push(`/columnDisplay?partCode=${item.code}&code=${ico.code}`);
        }
      },

      typeLink(str) {
        switch (str) {
          case "index":
            return "/index";

          case "about":
            return `/about`;

          case "join":
            return "/hr";

          case "contact":
            return "/contactUs";

          case "service":
            return "/service";
        }
      },

      toRecord() {
        window.open("https://beian.miit.gov.cn/?spm=a2c4g.11186623.0.0.1c9544b7EaFYC8#/Integrated/index", "_blank");
      }

    };
    return { ...methods,
      router,
      imgUrl
    };
  }

});