import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-261177b2"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.isExternal ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle(_ctx.styleExternalIcon),
    class: _normalizeClass(["svg-external-icon svg-icon", _ctx.className])
  }, null, 6)) : (_openBlock(), _createElementBlock("svg", {
    key: 1,
    class: _normalizeClass(["svg-icon", _ctx.className]),
    "aria-hidden": "true"
  }, [_createElementVNode("use", {
    "xlink:href": _ctx.iconName
  }, null, 8, _hoisted_1)], 2));
}