import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { getConfig, getPartPage } from "@/http/home";
import Header from "./header.vue";
import Footer from "./footer.vue";
import { imgUrl } from "@/utils/urlManage";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const _this = reactive({
      configData: {},
      productBarList: [],
      productBarLists: []
    });

    const router = useRouter();
    const route = useRoute();
    const methods = {
      // 获取网站配置
      getProductBarMethod() {
        getConfig({
          orgCode: sessionStorage.getItem("orgCode") || ""
        }).then(res => {
          if (res.data.code == 200) {
            _this.configData = res.data.data;

            if (res.data.data.iconFileUrl) {
              sessionStorage.setItem("icoUrl", imgUrl(res.data.data.iconFileUrl));
              document.getElementById("titleImg").href = imgUrl(res.data.data.iconFileUrl);
            } else {
              sessionStorage.removeItem("icoUrl");
            }

            sessionStorage.setItem("webName", res.data.data.name);
            document.getElementsByTagName("title")[0].innerText = res.data.data.name;
            document.documentElement.style.setProperty("--subjectColor", res.data.data.themeColor);
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      // 获取栏目列表
      getPartPageList() {
        getPartPage({
          orgCode: sessionStorage.getItem("orgCode") || "",
          listCode: 3,
          location: 1
        }).then(async res => {
          if (res.data.code == 200) {
            _this.productBarLists = JSON.parse(JSON.stringify(res.data.data));
            await res.data.data.forEach(ele => {
              if (ele.children && ele.children.length > 0) {
                ele.children.unshift(ele);
              }
            });
            _this.productBarList = res.data.data;
            let flag = true;
            res.data.data.forEach(ele => {
              if (ele.typeCode == "inbuilt") {
                if (route.path == "/index" && ele.link == "index") {
                  flag = false;
                } else if (route.path == "/about" && ele.link == "about") {
                  flag = false;
                } else if (route.path == "/hr" && ele.link == "join") {
                  flag = false;
                } else if (route.path == "/contactUs" && ele.link == "contact") {
                  flag = false;
                } else if (route.path == "/service" || route.path == "/deviceDetailsByCode" && ele.link == "service") {
                  flag = false;
                } else if (route.path == "/newsDetail") {
                  flag = false;
                }
              } else if (ele.typeCode == "defined") {
                if (route.path == "/columnDisplay") {
                  flag = false;
                }
              }

              if (ele.children) {
                ele.children.forEach(element => {
                  if (element.typeCode == "inbuilt") {
                    if (route.path == "/index" && element.link == "index") {
                      flag = false;
                    } else if (route.path == "/about" && element.link == "about") {
                      flag = false;
                    } else if (route.path == "/hr" && element.link == "join") {
                      flag = false;
                    } else if (route.path == "/contactUs" && element.link == "contact") {
                      flag = false;
                    } else if (route.path == "/service" || route.path == "/deviceDetailsByCode" && ele.link == "service") {
                      flag = false;
                    } else if (route.path == "/newsDetail") {
                      flag = false;
                    }
                  } else if (element.typeCode == "defined") {
                    if (route.path == "/columnDisplay") {
                      flag = false;
                    }
                  }
                });
              }
            });

            if (flag) {
              methods.changeMenu(res.data.data[0], res.data.data[0]);
            }
          } else {
            ElMessage.error(res.statusText);
          }
        });
      },

      changeMenu(item, ico) {
        if (ico.typeCode == "inbuilt") {
          if (item.code == ico.code) {
            router.push(`${methods.typeLink(ico.link)}?onlyName=${ico.name}&subtitle=${ico.subtitle}`);
          } else {
            if (item.typeCode == "inbuilt") {
              router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&url=${methods.typeLink(item.link)}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            } else if (item.typeCode == "link") {
              router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&typeCode=${item.typeCode}&url=${item.link}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            } else if (ico.typeCode == "defined") {
              router.push(`${methods.typeLink(ico.link)}?oldName=${item.name}&url=/columnDisplay?partCode=${ico.code}&code=${""}&onlyName=${ico.name}&subtitle=${ico.subtitle}`);
            }
          }
        } else if (ico.typeCode == "link") {
          window.open(ico.link, "_blank");
        } else if (ico.typeCode == "defined") {
          router.push(`/columnDisplay?partCode=${ico.code}&code=${""}`);
        } else {
          router.push(`/columnDisplay?partCode=${item.code}&code=${ico.code}`);
        }
      },

      typeLink(str) {
        switch (str) {
          case "index":
            return "/index";

          case "about":
            return `/about`;

          case "join":
            return "/hr";

          case "contact":
            return "/contactUs";

          case "service":
            return "/service";
        }
      }

    };
    onMounted(async () => {
      methods.getProductBarMethod();
      methods.getPartPageList();
    });
    return { ...toRefs(_this),
      ...methods
    };
  },

  components: {
    Header,
    Footer
  }
});