import $http from "./index";
// 头部导航栏数据
export const getHeaderNav = () =>
  $http({
    url: `/nav/head/data`,
    method: "get",
  });
// 获取轮播图数据
interface BannerData {
  orgCode?: string;
}
export const getBanner = (data: BannerData) =>
  $http({
    url: `/api/public/website/banner/getBannerList`,
    method: "post",
    data,
  });

// 获取产品中心条数据(网页展示数据)
export const getProductBar = () =>
  $http({
    url: `/productDisplay/productCategory/data/show`,
    method: "post",
  });

// 获取解决方案条数据(网页展示数据)
export const getResolution = (data: any) =>
  $http({
    url: `/solution/solutionCategory/data/show`,
    method: "post",
    data,
  });
//
export const getDisplayArea = (data: any) =>
  $http({
    url: `/api/public/website/displayArea/getDisplayArea`,
    method: "post",
    data,
  });

// 新闻中心 --- 企业动态/行业新闻(最新三条数据)
export const getLatestNews = () =>
  $http({
    url: `/newsInformation/article/data/show`,
    method: "post",
  });
// 合作伙伴数据
export const getPartnerList = (data: any) =>
  $http({
    url: `/api/public/website/partners/getPartnersList`,
    method: "post",
    data,
  });
// 底部导航栏数据
export const getFooterNav = () =>
  $http({
    url: `/nav/bottom/data`,
    method: "get",
  });
//获取服务支持列表
export const getService = () =>
  $http({
    url: `/serviceSupport/serviceCategory/data/sideMenu`,
    method: "post",
  });

// 查询网站配置信息
export const getConfig = (data: { orgCode: string }) =>
  $http({
    url: `/api/public/website/config/getConfig`,
    method: "post",
    data,
  });
//查询栏目列表
export const getPartPage = (data: any) =>
  $http({
    url: `/api/public/website/part/getPartList`,
    method: "post",
    data,
  });
//查询栏目详情
export const getPart = (data: { code: string; orgCode: string }) =>
  $http({
    url: `/api/public/website/part/getPart`,
    method: "post",
    data,
  });
//查询通用模板列表
export const getContentTemplateTypeList = (data: {
  listCode: number;
  orgCode: string;
  partCode: string;
}) =>
  $http({
    url: `/api/public/website/contentTemplateType/getContentTemplateTypeList`,
    method: "post",
    data,
  });
//查询通用模板xiangqing
export const getContentTemplateDetail = (data: {
  code: string;
  orgCode: string;
  partCode: string;
}) =>
  $http({
    url: `/api/public/website/contentTemplateDetail/getContentTemplateDetail`,
    method: "post",
    data,
  });

//查询模板详情
export const getTemplate = (data: { code: string; orgCode: string }) =>
  $http({
    url: `/api/public/website/template/getTemplate`,
    method: "post",
    data,
  });
//查
//根据域名获取orgcode
export const getOrgCodeByConfig = (data: { siteAddress: string }) =>
  $http({
    url: `/api/public/website/config/getOrgCodeByConfig`,
    method: "post",
    data,
  });
export interface NavData {
  name: string;
  children: childrenType[];
}
interface childrenType {
  name: string;
}
export interface AddConfig {
  recordNumber: string;
  code?: string;
  crinfoEn: string;
  companyName: string;
  siteAddress: string;
  iconFileCode: string;
  iconFileUrl: string;
  logoFileUrl: string;
  crinfoCn: string;
  version?: number;
  wechatQrCode: string;
  wechatQrUrl: string;
  themeColor: string;
  companyAddress: string;
  name: string;
  contactNumber: string;
  logoFileCode: string;
  email: string;
}
