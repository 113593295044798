import { isExternal as external } from "@/utils/validate";
import { defineComponent, computed } from "vue";
export default defineComponent({
  props: {
    // icon 图标
    icon: {
      type: String,
      required: true
    },
    // 图标类名
    className: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    /**
     * 判断是否为外部图标
     */
    const isExternal = computed(() => external(props.icon));
    /**
     * 外部图标样式
     */

    const styleExternalIcon = computed(() => ({
      mask: `url(${props.icon}) no-repeat 50% 50%`,
      "-webkit-mask": `url(${props.icon}) no-repeat 50% 50%`
    }));
    /**
     * 项目内图标
     */

    const iconName = computed(() => `#icon-${props.icon}`);
    return {
      isExternal,
      styleExternalIcon,
      iconName
    };
  }

});