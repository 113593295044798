const SERVER_BASE_URL = (window as any).g.baseURL;

// 获取oss前缀
export const imgUrl = (url: any) => {
  if (url != undefined) {
    // url 为 http://开头直接返回
    if (url.substring(0, 8) == "https://") {
      return url;
    }
    // 不为 http:// 开头则拼接
    else {
      return `${SERVER_BASE_URL}/file` + url;
    }
  } else {
    return ``;
  }
};
