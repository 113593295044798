import { defineComponent, ref, reactive, toRefs, onMounted, watch, nextTick } from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { getResolutionNav } from '@/http/solution';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { imgUrl } from '@/utils/urlManage';
import emitter from "@/mitt";
export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const carouselRef = ref();
    const data = reactive({
      // 产品数据
      productDeatil: [],
      // 侧边栏数据
      menuList: [],
      onlyDetail: {},
      pathList: [{
        title: "解决方案",
        goPath: `/solution`
      }],
      num: 0
    });
    const menuRef = ref(null);
    const methods = {
      //重置面包屑
      resertPath() {
        data.pathList = [{
          title: "解决方案",
          goPath: `/solution`
        }];
      },

      getResolutionNavMethod() {
        methods.resertPath(); // 获取解决方案侧边栏

        getResolutionNav().then(res => {
          if (res.data.code == 200) {
            data.menuList = res.data.data;

            if (!route.params.id) {
              methods.changeMeauOne(res.data.data[0]);
            } else {
              methods.changMeau();
            }
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      //进入页面判断
      changMeau() {
        data.menuList.forEach((item, i) => {
          if (item.id == Number(route.params.id)) {
            methods.changeMeauOne(item);
            data.num = item.id;
          } else {
            if (item.children) {
              var _item$children;

              (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.forEach(ico => {
                if (ico.id == Number(route.params.id)) {
                  methods.changeMeauTwo(ico, item);
                  data.num = Number('1' + ico.id);
                } else {
                  var _ico$solutions;

                  (_ico$solutions = ico.solutions) === null || _ico$solutions === void 0 ? void 0 : _ico$solutions.forEach(model => {
                    if (model.id == Number(route.params.id)) {
                      methods.changeMeauThree(model, item, ico);
                      data.num = Number('2' + model.id);
                    }
                  });
                }
              });
            } else {
              var _item$solutions;

              (_item$solutions = item.solutions) === null || _item$solutions === void 0 ? void 0 : _item$solutions.forEach(model => {
                if (model.id == Number(route.params.id)) {
                  methods.changeMeauProducts(model, item);
                  data.num = Number('1' + model.id);
                }
              });
            }
          }
        });
      },

      /**
       * 点击头部面包屑跳转
       * @param title
       */
      changeBread(title) {
        router.push(title.goPath);

        if (title.title == '解决方案') {
          methods.changeMeauOne(data.menuList[0]);
          let timer = setTimeout(() => {
            location.reload();
            clearTimeout(timer);
          });
        }
      },

      /**
       * 点击一级修改右侧数据
       * @param str
       */
      changeMeauOne(str) {
        data.productDeatil = [];
        data.num = str.id; //表头更改

        methods.resertPath();
        data.pathList.push({
          title: str.name,
          goPath: `/solution/list/${str.id}`
        });

        if (str.children) {
          data.productDeatil = str.children;
        } else {
          data.productDeatil.push(str);
        }

        sessionStorage.setItem('solutionDeatil', JSON.stringify(data.productDeatil));
        emitter.emit("get-meau-detail");
        router.push(`/solution/list/${str.id}`);
      },

      /**
       *
       * @param str 二级详情数据
       * @param name 一级的name
       */
      changeMeauProducts(str, oneStr) {
        // methods.getProductDeailMethod(str.id)
        methods.resertPath();
        data.pathList.push({
          title: oneStr.name,
          goPath: `/solution/list/${oneStr.id}`
        });
        data.pathList.push({
          title: str.title,
          goPath: `/solution/detail/id=${str.id}`
        });
        data.num = Number('1' + str.id);
        router.push(`/solution/detail/${str.id}`);
      },

      /**
       * 点击二级修改右侧数据
       * @param str
       */
      changeMeauTwo(str, oneStr) {
        data.productDeatil = [];
        data.num = Number('1' + str.id);
        data.productDeatil.push(str);
        sessionStorage.setItem('solutionDeatil', JSON.stringify(data.productDeatil));
        emitter.emit("get-meau-detail");
        methods.resertPath();
        data.pathList.push({
          title: oneStr.name,
          goPath: `/solution/list/${oneStr.id}`
        });
        router.push(`/solution/list/${str.id}`);
      },

      /**
       * 点击三级修改右侧数据
       * @param str
       */
      changeMeauThree(str, oneStr, twoStr) {
        // methods.getProductDeailMethod(str.id)
        methods.resertPath();
        data.num = Number('2' + str.id);
        data.pathList.push({
          title: oneStr.name,
          goPath: `/solution/list/${oneStr.id}`
        });
        data.pathList.push({
          title: str.title,
          goPath: `/solution/detail/${str.id}`
        });
        router.push(`/solution/detail/${str.id}`);
      }

    };
    onMounted(() => {
      if (route.path.slice(0, 9) == "/solution") {
        methods.getResolutionNavMethod();
      }
    });
    emitter.on("pro-getSolutionMenu", () => {
      nextTick(() => {
        location.reload();
      });
    }); // 监听路由变化

    watch(() => route.params.id, (newValue, oldValue) => {
      // 判断路由是否为 /solution/detail/XXX
      if (route.path.slice(0, 9) == "/solution" && newValue != '') {
        methods.changMeau();
      }
    }); // 监听路由变化
    // watch(() => route.path, (newValue, oldValue) => {
    //   if (newValue.slice(0,9)== "/solution") {
    //     methods.getResolutionNavMethod()
    //   }
    // })

    return { ...toRefs(data),
      ArrowRight,
      ...methods,
      imgUrl,
      carouselRef,
      route,
      menuRef
    };
  }

});