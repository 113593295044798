// 在app.vue中使用，设置rem的值
function setRem() {
  const baseWidth = 1920; // 设计稿宽度
  // 窗口宽度  1920x = 16  2000x = ？  =》 x=16/1920  => = 2000*16/1920
  const clientWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  let rootValue = 16;
  let fontSize = (clientWidth / baseWidth) * rootValue;
  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName("html")[0];
  //设置根元素字体大小 Math.max(11,fontSize)
  htmlDom.style.fontSize = Math.max(11, fontSize) + "px";
}
export { setRem };
